<template>
  <div class="row space-between items-center full-width q-mt-md">
    <div class="row q-gutter-x-md">
      <slot name="primary-actions" />
    </div>
    <q-space />
    <div class="row q-gutter-x-md">
      <slot name="secondary-actions" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FormActionsSection"
};
</script>

<style scoped lang="scss"></style>
