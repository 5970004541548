<template>
  <div>
    <PageDynamicBreadcrumb />
    <PageHeader title="Ads Inventory Library Selector Tool" />

    <div v-if="populating" class="row justify-center items-center">
      <q-spinner color="primary" size="3em" />
    </div>
    <q-card v-else class="q-mb-md" style="width: min-content">
      <q-card-section
        v-if="
          !!currentEligibleVehicle && !!currentEligibleVehicle.selectedImage
        "
      >
        <q-card-section horizontal class="q-gutter-x-lg">
          <div style="width: 550px">
            <!--<h5 class="q-ma-none q-pb-md text-weight-bold">
              Select Product Image
            </h5>-->
            <div
              class="product-image-selector q-pa-sm rounded-borders"
              style="background: #121212;"
            >
              <q-img
                :src="currentEligibleVehicle.selectedImage.url"
                spinner-color="white"
                transition="fade"
                style="width: 100%; height: 400px; background: #121212"
                fit="contain"
                :ratio="4 / 3"
              />
              <div
                class="product-image-selector__eligible-images flex no-wrap q-gutter-x-sm scroll-x overflow-auto q-mt-sm q-ml-none"
                :class="{ 'q-pb-xs': currentEligibleVehicle.images.length > 5 }"
              >
                <div
                  v-for="imageData in currentEligibleVehicle.images"
                  :key="imageData.id + '_eligibleProductImage'"
                  class="product-image-selector__eligible-image-container"
                >
                  <q-btn
                    padding="none"
                    @click="selectProductImage(imageData.url, imageData.id)"
                    :ref="imageData.id + '_eligibleProductImage'"
                    class="product-image-selector__eligible-image"
                    flat
                  >
                    <q-img
                      :src="imageData.url"
                      style="width: 80px; height: 60px; background: #121212"
                      fit="contain"
                      :ratio="4 / 3"
                    />
                  </q-btn>
                </div>
              </div>
            </div>
            <div class="flex justify-between q-mt-md">
              <q-btn
                label="Reject"
                color="subtle"
                icon="cancel"
                :disable="submitting"
                :loading="submittingReject"
                @click="rejectCurrentEligibleVehicleFromLibrary"
              />
              <q-btn
                color="grey-12"
                text-color="subtle"
                label="Skip"
                :disable="submitting"
                @click="setCurrentEligibleVehicle"
              />
              <q-btn
                label="Add"
                color="primary"
                icon="check_circle"
                :disable="submitting"
                :loading="submittingAdd"
                @click="addCurrentEligibleVehicleToLibrary"
              />
            </div>
          </div>
          <div class="col">
            <h5 class="q-ma-none q-pb-md text-weight-bold">
              Product Details
            </h5>
            <q-card-section class="q-pa-none">
              <q-list separator style="width: 400px">
                <q-separator />
                <q-item
                  v-for="(detail, i) in currentEligibleVehicle.detailsList"
                  :key="i + '_eligibleProductDetail'"
                  class="q-pa-sm"
                  style="min-height: unset"
                >
                  <q-item-section side style="min-width: 95px;">
                    <q-item-label>
                      <b>{{ detail.label }}</b>
                    </q-item-label>
                  </q-item-section>

                  <q-item-section>
                    <q-item-label style="font-size: 1.15em">{{
                      detail.value
                    }}</q-item-label>
                  </q-item-section>
                </q-item>
                <q-separator />
              </q-list>
            </q-card-section>
          </div>
        </q-card-section>
      </q-card-section>
    </q-card>

    <FormActionsSection>
      <template v-slot:primary-actions>
        <q-btn
          flat
          label="Back"
          to="/ads/inventory-library"
          color="subtle"
          :disable="submitting"
        />
      </template>
    </FormActionsSection>
  </div>
</template>

<script>
import axios from "axios";
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import FormActionsSection from "@/components/UI/FormActionsSection";

export default {
  name: "VehiclesInventoryLibraryVehicleSelector",
  components: { FormActionsSection, PageDynamicBreadcrumb, PageHeader },
  data() {
    return {
      populating: true,
      loading: false,
      submitting: false,
      submittingAdd: false,
      submittingReject: false,
      page: 0,
      eligibleVehicles: [],
      currentEligibleVehicle: {}
    };
  },
  mounted() {
    this.setCurrentEligibleVehicle();
  },
  methods: {
    getEligibleVehicles() {
      this.loading = true;
      ++this.page;
      return new Promise((resolve, reject) => {
        axios({
          url:
            "https://api-v2.jumpfeed.com/internal/ads/vehicles-inventory-library/get-eligible"
        })
          .then(resp => {
            this.eligibleVehicles = resp.data.data;
            if (this.eligibleVehicles.length === 0) {
              reject();
            }
            this.eligibleVehicles.forEach((eligibleVehicle, i) => {
              this.eligibleVehicles[i].selectedImage = {
                id: eligibleVehicle.images[0].id,
                url: eligibleVehicle.images[0].url
              };
              this.eligibleVehicles[i].detailsList = [
                {
                  label: "Year",
                  value: eligibleVehicle.year
                },
                {
                  label: "Make",
                  value: eligibleVehicle.make
                },
                {
                  label: "Model",
                  value: eligibleVehicle.model
                },
                {
                  label: "Price",
                  value: eligibleVehicle.price_formatted
                },
                {
                  label: "Mileage",
                  value: eligibleVehicle.mileage_formatted
                },
                {
                  label: "Dealership",
                  value: eligibleVehicle.dealer.name
                },
                {
                  label: "Location",
                  value:
                    eligibleVehicle.dealer.city +
                    ", " +
                    eligibleVehicle.dealer.state
                },
                {
                  label: "Product ID",
                  value: eligibleVehicle.product_id
                },
                {
                  label: "VIN",
                  value: eligibleVehicle.vin
                }
              ];
            });
            this.loading = false;
            resolve();
          })
          .catch(err => {
            reject();
            console.log(err);
          });
      });
    },
    setCurrentEligibleVehicle() {
      this.submitting = true;
      if (this.eligibleVehicles.length === 0) {
        this.getEligibleVehicles().then(() => {
          this.setCurrentEligibleVehicle();
        });
      } else {
        this.populating = false;
        this.currentEligibleVehicle = this.eligibleVehicles.shift();
        setTimeout(() => {
          this.selectProductImage(
            this.currentEligibleVehicle["images"][0]["url"],
            this.currentEligibleVehicle["images"][0]["id"]
          );
          this.submitting = false;
        }, 100);
      }
    },
    addCurrentEligibleVehicleToLibrary() {
      this.submitting = true;
      this.submittingAdd = true;

      axios
        .post(
          "https://api-v2.jumpfeed.com/internal/ads/vehicles-inventory-library/add",
          {
            product_id: this.currentEligibleVehicle.product_id,
            product_image_id: this.currentEligibleVehicle.selectedImage.id
          }
        )
        .then(() => {
          this.triggerActionOutcomeAlert("add", true);
        })
        .catch(err => {
          console.log(err);
          this.triggerActionOutcomeAlert("save", false);
        })
        .finally(() => {
          this.submitting = false;
          this.submittingAdd = false;
          this.setCurrentEligibleVehicle();
        });
    },
    rejectCurrentEligibleVehicleFromLibrary() {
      this.submitting = true;
      this.submittingReject = true;

      axios
        .post(
          "https://api-v2.jumpfeed.com/internal/ads/vehicles-inventory-library/reject-eligible",
          {
            product_id: this.currentEligibleVehicle.product_id
          }
        )
        .catch(err => {
          console.log(err);
          this.triggerActionOutcomeAlert("save", false);
        })
        .finally(() => {
          this.submitting = false;
          this.submittingReject = false;
          this.setCurrentEligibleVehicle();
        });
    },
    selectProductImage(imageUrl, id) {
      let eligibleImages = document.getElementsByClassName(
        "product-image-selector__eligible-image"
      );
      Array.from(eligibleImages).forEach(eligibleImage => {
        eligibleImage.classList.remove("--selected");
      });

      this.currentEligibleVehicle.selectedImage.url = imageUrl;
      this.currentEligibleVehicle.selectedImage.id = id;

      this.$refs[id + "_eligibleProductImage"][0].$el.classList.add(
        "--selected"
      );
    }
  }
};
</script>

<style lang="scss">
.product-image-selector__eligible-images {
  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4e4e4e;
    border-radius: 2px;
  }

  .product-image-selector__eligible-image-container {
    &:first-of-type {
      margin-left: 0 !important;
    }
  }

  .product-image-selector__eligible-image {
    @include transition();
    opacity: 0.7;
    border: 3px solid transparent;
  }
  .product-image-selector__eligible-image.--selected {
    border: 2px solid #1e6ae5;
    opacity: 1;
    filter: brightness(1.1);
  }
}
</style>
